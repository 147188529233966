<template>
  <v-container>
    <v-card
      class="mx-auto"
      elevation="8"
      width="80%"
      height="auto"
      :loading="saving"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="headline mb-1">
            クラス名を選択
          </v-list-item-title>
          <v-list-item-subtitle>
            クラス名の一部を入力すると、選択肢が表示されます。
            英文字のクラス名を選択して保存ボタンを押してください。
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            学生でクラス名が見つからない場合は、「該当なし」を、
            教職員の場合は、「専任教員」「非常勤講師」「その他職員」を選択して下さい。
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions>
        <v-autocomplete
          v-model="className"
          :loading="loading"
          :items="items"
          :search-input.sync="search"
          cache-items
          class="mx-4"
          flat
          hide-no-data
          hide-details
          label="クラス名"
          solo-inverted
        ></v-autocomplete>
        <v-spacer></v-spacer>
        <v-btn
          small
          class="ma-2"
          color="gray"
          @click="cancelEditClassName"
          :disabled="!className"
          >キャンセル</v-btn
        >
        <v-btn
          small
          class="ma-2"
          color="secondary"
          @click="saveClassName"
          :disabled="saving"
          >保存</v-btn
        ></v-card-actions
      >
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: "ClassName",
  data() {
    return {
      saving: false,
      className: this.$store.state.loginUser?.className,
      loading: false,
      items: [],
      search: null,
      classNames: [
        "SJA1",
        "SJA2",
        "SJA3",
        "SSA1",
        "SSA2",
        "SSA3",
        "EPJ",
        "EPS",
        "QJA",
        "QSA",
        "QJB1",
        "QSB1",
        "QSB2",
        "QSB3",
        "KJA",
        "KSA",
        "KSB",
        "BJ",
        "BS",
        "IJ",
        "IS",
        "IM",
        "DJ",
        "DS",
        "DKJ1",
        "DKJ2",
        "DKJ3",
        "DKJ4",
        "DKS1",
        "DKS2",
        "DKS3",
        "DKS4",
        "FJA",
        "FJB",
        "FJC",
        "FJD",
        "FSA",
        "FSB",
        "FSC",
        "FSD",
        "FSS",
        "HJA",
        "HJB",
        "HJD",
        "HJE",
        "HJF",
        "HSA",
        "HSB",
        "HSC",
        "HSD",
        "HSE",
        "HSF",
        "WJ",
        "WS",
        "TJ",
        "TS",
        "USCA",
        "UKANZ",
        "AE",
        "GSP",
        "JrC",
        "JC",
        "UV1a",
        "UV1b",
        "JBJ",
        "JVJa",
        "初級1",
        "初級2",
        "SrC",
        "JBSi",
        "JBSh",
        "JVS",
        "中級",
        "上級",
        "該当なし",
        "専任教員",
        "非常勤講師",
        "その他職員"
      ]
    };
  },
  computed: {},
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    }
  },
  methods: {
    saveClassName() {
      const name = this.className;
      this.$store.dispatch("setClassName", name);
    },
    cancelEditClassName() {
      this.$store.commit("CANCEL_EDOT_USER_CLASS_NAME");
    },
    querySelections(v) {
      this.loading = true;
      setTimeout(() => {
        this.items = this.classNames.filter(e => {
          return (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1;
        });
        this.loading = false;
      }, 500);
    }
  }
};
</script>
